<template>
  <form @submit.prevent="submit">
    <div>
      <location-bar
        v-if="action('regions') || action('countries') || action('districts')"
        :loading="loading"
        :flex-size="locationFlexSize"
        :with-filters="actions"
        :initial-region="initialRegion"
        :initial-country="initialCountry"
        :initial-district="initialDistrict"
        @selected-region="regionSelected"
        @selected-country="countrySelected"
        @selected-district="districtSelected"
      />

      <div
        class="row"
        v-if="action('roles') || action('groups')"
      >
        <div class="flex xs12">
          <h6>{{ $t('layout.filters.status') }}</h6>
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('groups') && action('roles')}"
          v-if="this.currentUser.can('Users', 'groups')"
        >
          <va-select
            v-model="selectedGroup"
            :label="$t('layout.form.groupInput')"
            :options="groupsList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            :loading="isLoading.groups"
            text-by="name"
            searchable
          />
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('groups') && action('roles')}"
          v-if="this.currentUser.can('Users', 'roles') && action('roles')"
        >
          <va-select
            v-model="selectedRole"
            :label="$t('users.inputs.roleInput')"
            :options="rolesList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            :loading="isLoading.roles"
            text-by="name"
            searchable
          />
        </div>
      </div>

      <div
        class="row"
        v-if="action('name')"
      >
        <div class="flex xs12">
          <h6>{{ $t('tables.actions.search') }}</h6>
        </div>
        <div class="flex xs12">
          <va-input
            v-model="nameQuery"
            :placeholder="$t('tables.actions.search')"
            removable
          />
        </div>
      </div>

      <div class="row" v-if="action('teamStatus')">
        <div class="flex xs12">
          <va-select
            v-model="selectedTeamStatus"
            :label="$t('layout.form.status')"
            :options="teamStatusList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
      </div>

      <div class="row" v-if="action('trainingType')">
        <div class="flex xs12">
          <va-select
            v-model="selectedTrainingType"
            :label="$t('layout.form.training_type')"
            :options="trainingTypeList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
      </div>

      <div class="row" v-if="action('goalType')">
        <div class="flex xs12">
          <va-select
            v-model="selectedGoalType"
            :label="$t('goals.inputs.type')"
            :options="goalsTypeList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            text-by="name"
            searchable
          />
        </div>
      </div>

      <div
        class="row"
        v-if="action('yearSince') || action('yearUntil')"
      >
        <div class="flex xs12">
          <h6>{{ $t('layout.filters.date') }}</h6>
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('yearSince') && action('yearUntil')}"
          v-if="action('yearSince')"
        >
          <va-select
            v-model="yearSince"
            :label="$t('layout.form.yearSinceInput')"
            :options="yearSinceList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            searchable
          />
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('yearSince') && action('yearUntil')}"
          v-if="action('yearUntil')"
        >
          <va-select
            v-model="yearUntil"
            :label="$t('layout.form.yearUntilInput')"
            :options="yearUntilList"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            searchable
          />
        </div>
      </div>
      <div
        class="row"
        v-if="action('dateSince') || action('dateUntil')"
      >
        <div class="flex xs12">
          <h6>{{ $t('layout.filters.date') }}</h6>
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('dateSince') && action('dateUntil')}"
          v-if="action('dateSince')"
        >
          <va-date-picker
            id="date-since"
            :label="$t('layout.form.dateSince')"
            :config="dateMinConfig"
            v-model="dateSince"
          />
        </div>
        <div
          class="flex xs12"
          :class="{'md6': action('dateSince') && action('dateUntil')}"
          v-if="action('dateUntil')"
        >
          <va-date-picker
            id="date-until"
            :label="$t('layout.form.dateUntil')"
            :config="dateMaxConfig"
            v-model="dateUntil"
          />
        </div>
      </div>

      <div class="row justify-content-md-start">
        <div class="flex xs12">
          <va-button-group>
            <va-button
              color="primary"
              :disabled="loading"
            >
              <text-loading
                :loading="loading"
                icon="fa fa-filter"
              >
                {{ $t('layout.filters.button') }}
              </text-loading>
            </va-button>
            <va-button
              color="danger"
              type="button"
              :disabled="loading"
              @click.prevent="clearFilters"
            >
              <text-loading
                :loading="loading"
                icon="fa fa-times"
              >
                {{ $t('layout.filters.clear') }}
              </text-loading>
            </va-button>
          </va-button-group>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
const LocationBar = () => import(/* webpackPrefetch: true */ './Bars/Location')

export default {
  name: 'filters-form',
  components: { LocationBar },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    actions: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    locationFlexSize () {
      const sizes = ['md12', 'md6', 'md4']
      const filters = this.actions.filter(x => ['regions', 'countries', 'districts'].includes(x))

      return sizes[filters.length - 1]
    },
    dateMinConfig () {
      let max = null
      if (this.dateUntil) {
        max = this.dateUntil
      }
      return {
        maxDate: max,
      }
    },
    dateMaxConfig () {
      let min = null
      if (this.dateSince) {
        min = this.dateSince
      }
      return {
        minDate: min,
      }
    },
    teamStatusList () {
      return [
        { id: 0, name: this.$t('teams.status.removed'), value: -1 },
        { id: 1, name: this.$t('teams.status.new'), value: 0 },
        { id: 2, name: this.$t('teams.status.renewed'), value: 1 },
        { id: 3, name: this.$t('teams.status.Registered'), value: 2 },
      ]
    },
    goalsTypeList () {
      return [
        {
          id: 0,
          name: this.$t('goals.types.predication'),
          value: 0,
        },
        {
          id: 1,
          name: this.$t('goals.types.mti'),
          value: 1,
        },
        {
          id: 2,
          name: this.$t('goals.types.training'),
          value: 2,
        },
        {
          id: 3,
          name: this.$t('goals.types.instructors'),
          value: 3,
        },
        {
          id: 4,
          name: this.$t('goals.types.planters'),
          value: 4,
        },
      ]
    },
  },
  data () {
    return {
      verifying: false,
      assignedError: false,
      isLoading: {
        roles: false,
        groups: false,
      },
      isError: {
        roles: false,
        groups: false,
      },
      dateSince: null,
      dateUntil: null,
      initialRegion: null,
      initialCountry: null,
      initialDistrict: null,
      selectedRegion: null,
      selectedCountry: null,
      selectedDistrict: null,
      yearSince: '',
      yearSinceList: [],
      yearUntil: '',
      yearUntilList: [],
      groupsList: [],
      options: [
        { text: 'Uno', value: 'A' },
        { text: 'Dos', value: 'B' },
        { text: 'Tres', value: 'C' },
      ],
      selectedGroup: '',
      rolesList: [],
      selectedRole: '',
      nameQuery: '',
      selectedTeamStatus: '',
      selectedGoalType: '',
    }
  },
  watch: {
    'currentUser.permissions' (val) {
      this.initialData()
    },
    yearSince (val) {
      this.getYearsUntil(val)
    },
    yearUntil (val) {
      this.getYearsSince(val)
    },
  },
  created () {
    this.initialData()
  },
  methods: {

    async setUser (val) {
      this.groupsList = this.options
      this.selectedGroup.alias = this.selectedGroup.value
      console.log(this.groupsList)
    },
    action (action) {
      if (this.actions.length === 0) return true
      return this.actions.some(a => a === action)
    },
    getYearsSince (max) {
      const min = 2018
      const currentYear = (new Date()).getFullYear()
      max = (Number(max) || currentYear) + 1
      const years = []
      for (let i = min; i < max; i++) {
        const y = i
        years.push(y.toString())
      }
      this.yearSinceList = years
    },
    nameGroups () {
      this.groupsList.forEach(p => {
        p.name = this.$t('groups.' + p.name)
      })
    },
    getYearsUntil (min) {
      const currentYear = (new Date()).getFullYear()
      const max = (currentYear) + 1
      min = Number(min) || 2018
      const years = []
      for (let i = min; i <= max; i++) {
        const y = i
        years.push(y.toString())
      }
      this.yearUntilList = years
    },
    async initialData () {
      if (this.action('trainingType')) {
        this.asyncFindTrainingTypes('')
      }
      if (this.action('role')) {
        this.asyncFindRole('&sort=parent_id&direction=asc')
      }
      if (this.currentUser.can('Users', 'groups')) {
        this.asyncFindGroup('&sort=name&direction=asc')
      }
      if (this.currentUser.can('Users', 'roles')) {
        this.asyncFindRole('')
      }
      if (this.action('group')) {
        this.asyncFindGroup('&sort=parent_id&direction=asc')
      }
      if (this.action('yearSince')) {
        this.getYearsSince()
      }
      if (this.action('yearUntil')) {
        this.getYearsUntil()
      }
    },
    async asyncFindTrainingTypes (query) {
      if (this.currentUser.can('TrainingTypes', 'index')) {
        await this.asyncFind(query, 'types', 'trainings/types')
        if (this.isError.types) {
          return this.asyncFindTrainingTypes(query)
        }
      } else if (this.selectedType !== null) {
        this.typesList = [
          this.selectedType,
        ]
      }
    },
    async asyncFindGroup (query) {
      if (this.currentUser.can('Users', 'groups')) {
        await this.asyncFind(query, 'groups', 'users/groups')
        if (this.isError.groups) {
          this.asyncFindGroup(query)
        }
        this.nameGroups()
      }
    },
    async asyncFindRole (query) {
      if (this.currentUser.can('Users', 'roles')) {
        await this.asyncFind(query, 'roles', 'users/roles')
        if (this.isError.roles) {
          this.asyncFindRole(query)
        }
      }
    },
    async asyncFind (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      let response = false
      try {
        response = await this.$http.get(`/${route}?q=${query}`)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = false
        return
      }

      this[type + 'List'] = response.data.data || []
      this.isLoading[type] = false
    },
    regionSelected (region) {
      this.selectedRegion = region
      // this.setUser(1)
    },
    countrySelected (country) {
      this.selectedCountry = country
    },
    districtSelected (district) {
      this.selectedDistrict = district
    },
    async submit () {
      const filters = {}
      if (this.selectedRegion && this.selectedRegion !== '') {
        filters.region = this.selectedRegion.id
      }
      if (this.selectedCountry && this.selectedCountry !== '') {
        filters.country = this.selectedCountry.id
      }
      if (this.selectedDistrict && this.selectedDistrict !== '') {
        filters.district = this.selectedDistrict.id
      }
      if (this.selectedGroup && this.selectedGroup !== '') {
        filters.group = this.selectedGroup.id
      }
      if (this.selectedRole && this.selectedRole !== '') {
        filters.role = this.selectedRole.id
      }
      if (this.selectedTeamStatus && this.selectedTeamStatus !== '') {
        filters.teamStatus = this.selectedTeamStatus.value
      }
      if (this.selectedGoalType && this.selectedGoalType !== '') {
        filters.goalType = this.selectedGoalType.value
      }
      if (this.nameQuery) {
        filters.name = this.nameQuery
      }
      if (this.yearSince) {
        filters.yearSince = this.yearSince
      }
      if (this.yearUntil) {
        filters.yearUntil = this.yearUntil
      }
      if (this.dateSince) {
        filters.dateSince = this.dateSince
      }
      if (this.dateUntil) {
        filters.dateUntil = this.dateUntil
      }

      this.$emit('filter', filters)
    },
    async clearFilters () {
      this.dateSince = null
      this.dateUntil = null
      this.initialRegion = null
      this.initialCountry = null
      this.initialDistrict = null
      this.selectedRegion = null
      this.selectedCountry = null
      this.selectedDistrict = null
      this.selectedTeamStatus = ''
      this.selectedGoalType = ''
      this.selectedGroup = ''
      this.selectedRole = ''
      this.nameQuery = ''

      this.$emit('clear-filters')
    },
  },
}
</script>
